<template>
  <div class="unsupported-overlay" v-if="$store.state.config.isUnsupportedDevice">
    <div>Pre spustenie aplikácie použite mobilný telefón alebo tablet.</div>
  </div>
  <div class="body" v-else>
    <div class="app-nav" v-if="showNav">
      <router-link to="/">Intro</router-link>
      <router-link to="/authorize">Authorize</router-link>
      <router-link to="/overview">Overview</router-link>
      <router-link to="/snap/1">Snap</router-link>
      <router-link to="/recap">Recap</router-link>
      <router-link to="/vehicle-info">Vehicle info</router-link>
      <router-link to="/thank-you">Thank you</router-link>
    </div>
    <LoadingOverlay
      v-if="$store.state.config.isLoading"
      :messages="[]"
    />
    <LoadingOverlay
      v-if="$store.state.config.isFinalSubmitLoading"
      :messages="[
        'Spracovávam fotografie',
        'Ukladám doplnkové informácie',
        'Generujem dokumenty',
        'Takmer hotovo…',
      ]"
    />
    <AppHeader/>
    <router-view/>
  </div>
</template>

<script>
import '@gds/styles/canvas.scss';

import LoadingOverlay from '@gds/common/calculators/LoadingOverlay/';
import AppHeader from '@/components/AppHeader.vue';

export default {
  components: {
    LoadingOverlay,
    AppHeader,
  },
  computed: {
    showNav() {
      return process.env.NODE_ENV === 'development';
    },
  },
  methods: {
    detectApiBase() {
      const { hostname } = window.location;
      const productionsEnvs = [
        'obh.sk',
      ];
      const testEnvs = [
        'test.obh.sk',
        'selfinspections.test.generali.sk',
      ];
      if (productionsEnvs.includes(hostname)) {
        this.$store.state.config.isProduction = true;
        this.$store.state.config.apiBase = 'https://selfinspections-backend.generali.sk/api';
      } else if (testEnvs.includes(hostname)) {
        this.$store.state.config.apiBase = '//selfinspections-backend.test.generali.sk/api';
      }
    },
    detectDevice() {
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      if (mobileRegex.test(navigator.userAgent) === false) {
        this.$store.state.config.isUnsupportedDevice = true;
      }
    },
  },
  mounted() {
    this.detectApiBase();
    this.detectDevice();
    this.$router.push('/');
  },
};
</script>

<style lang="scss">
@import "@gds/styles/global.scss";

.container {
  padding: 1.25rem;
  max-width: 40rem;
  margin: 0 auto;
}

.page-title {
  text-align: center;
  font-size: $font-size-500;
  margin: 0 0 2rem;
}

.page-subtitle {
  text-align: center;
  font-size: $font-size-200;
  margin-bottom: 2rem;
}

.page-title + .page-subtitle {
  margin-top: -1.5rem;
}

$media-list-gutter: 10px;
.media-list-heading {
  margin: 0 -1.25rem 1.5rem !important;
}

.media-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 - $media-list-gutter;
}

.media-preview {
  max-width: 160px;
  padding: 0 $media-list-gutter;
  margin-bottom: 20px;
  .title {
    font-size: $font-size-200;
    margin-bottom: 0.5em;
  }
  .imagewrap {
    position: relative;
  }
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  .edit {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }
}
</style>

<style lang="scss" scoped>
.app-nav {
  background-color: gray;
  padding: 0.25rem;
  font-size: 0.75rem;
  margin: 0;
  position: fixed;
  z-index: 1;
  left: 0.25rem;
  bottom: 0.25rem;
  max-width: 45%;
  &, a {
    color: white;
  }
  a {
    display: inline-block;
    margin: 0 0.25rem;
  }
}
.unsupported-overlay {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}
</style>
