<template>
  <div class="container">
    <h1 class="page-title">Vitajte</h1>
    <p class="page-subtitle">
      Pre úspešný vstup do poistenia v Generali nafoťte vaše vozidlo, prosím. Pripravte si aj veľký technický preukaz a postupujte podľa inštrukcií
    </p>

    <div class="blurbs">
      <IntroBlurb
        :icon="icons.calendar"
        text="Samoobhliadku vykonajte najskôr 48&nbsp;hodín pred začiatkom platnosti poistenia"
      />
      <IntroBlurb
        :icon="icons.info"
        text="Plnú poistnú ochranu získate až po vykonaní samoobhliadky"
        :additionalText="coverageAdditionalInfo"
      />
      <IntroBlurb
        :icon="icons.garage"
        text="Ak je vozidlo poškodené, kontaktujte nás na 02 / 38 11 11 17"
      />
    </div>

    <BaseButton
      block
      @click="$router.push('/authorize')"
    >
      Začať
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@gds/components/BaseButton';
import IntroBlurb from '@/components/IntroBlurb.vue';
import { faCalendarAlt, faExclamationCircle, faCarGarage } from '@fortawesome/pro-light-svg-icons';

export default {
  name: 'Home',
  components: {
    BaseButton,
    IntroBlurb,
  },
  data() {
    return {
      icons: {
        calendar: faCalendarAlt,
        info: faExclamationCircle,
        garage: faCarGarage,
      },
    };
  },
  computed: {
    coverageAdditionalInfo() {
      if (this.$store.state.config.product === 'HPL') {
        return 'Spoluúčasť (suma, ktorou sa podieľate na prípadnej škode) je pred vykonaním samoobhliadky 75% / 5000 €. Po dokončení a akceptovaní samoobhliadky vám spoluúčasť klesne na výšku dojednanú v zmluve.';
      }
      return 'Riziká živel, výtlk, stret so zverou a hlodavec budú kryté až po dokončení a akceptovaní samoobhliadky.';
    },
  },
  methods: {
    detectProduct() {
      const urlParams = new URLSearchParams(window.location.search);
      const product = urlParams.get('p');
      switch (product) {
        case 'generali_hav':
          this.$store.state.config.product = 'HPL';
          break;
        default:
          this.$store.state.config.product = 'PZP';
      }
    },
    detectContractNumber() {
      const urlParams = new URLSearchParams(window.location.search);
      const contractNumber = urlParams.get('c');
      this.$store.state.policy.contractNumber = contractNumber;
    },
  },
  created() {
    this.detectProduct();
    this.detectContractNumber();
  },
};
</script>

<style lang="scss" scoped>
.blurbs {
  margin-bottom: 3rem;
}
</style>
