<template>
  <div class="container">
    <h1 class="page-title">Čo od vás budeme potrebovať</h1>

    <div
      v-for="group in $store.getters.mediaRequestedGroups"
      :key="group.id"
    >
      <BaseHeading
        size="small"
        class="media-list-heading"
      >
        {{ group.title }}
      </BaseHeading>
      <div class="media-list">
        <div
          class="media-preview"
          v-for="media in group.items"
          :key="media.name"
        >
          <div class="title">
            {{ media.title.split('/')[0] }}
            <small v-if="media.required === false">(nepovinné)</small>
          </div>
          <div class="imagewrap">
            <img
              class="image"
              :src="require(`@/assets/instructions/${media.image}`)"
              :alt="media.title"
            >
          </div>
        </div>
      </div>
    </div>
    <BaseButton
      block
      @click="$router.push('/snap/1')"
    >
      Pokračovať
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@gds/components/BaseButton';
import BaseHeading from '@gds/components/BaseHeading';

export default {
  name: 'Home',
  components: {
    BaseButton,
    BaseHeading,
  },
  data() {
    return {
    };
  },
};
</script>
