const checkImage = (image) => {
  const errors = [];

  if (image.type !== 'image/jpeg') {
    errors.push('other-type');
  }

  const currentTime = new Date();
  // Max 3 hours
  if (image.lastModified + (1000 * 60 * 60 * 3) <= currentTime.getTime()) {
    errors.push('not-recent');
  }

  // Print status - reset
  return errors;
};

export default checkImage;
