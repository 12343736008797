import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const getGroupIds = (mediaList) => {
  const groupIdsRaw = mediaList.map((m) => m.type);
  const groupIds = [...new Set(groupIdsRaw)];
  return groupIds;
};

const store = new Vuex.Store({
  state: {
    config: {
      apiBase: 'http://localhost:5000/api',
      product: 'PZP',
      isProduction: false,
      isUnsupportedDevice: false,
      isLoading: false,
      isFinalSubmitLoading: false,
      loadingProgress: {
        active: false,
        current: 0,
        total: 0,
      },
    },
    policy: {
      contractNumber: '',
      customerIdentification: '',
    },
    mediaRequested: [],
    mediaUploaded: [],
    vehicleInfo: {
      notes: '',
      acceptance: null,
    },
    location: {
      lat: '',
      lng: '',
      error: null,
    },
    dataLayerActionsSent: [],
  },
  getters: {
    mediaRequestedCount: (state) => state.mediaRequested.length,
    getMediaTitleFromName: (state) => (name) => state.mediaRequested.find(
      (media) => media.name === name,
    ).title,
    mediaSkipped: (state) => {
      const { mediaUploaded } = state;
      const skipped = mediaUploaded.filter((m) => m.isSkipped);
      if (!skipped.length) {
        return [];
      }
      return skipped.map((m) => m.name);
    },
    mediaSkippedTitles: (state, getters) => {
      const names = getters.mediaSkipped.map(
        (name) => getters.getMediaTitleFromName(name),
      );
      return names.join(', ');
    },
    mediaRequestedGroups: (state) => {
      const translations = {
        photo: 'Fotky auta',
        document: 'Fotky technického preukazu',
      };

      const groupIds = getGroupIds(state.mediaRequested);
      const groups = groupIds.map((id) => ({
        id,
        title: translations[id] || id,
        items: state.mediaRequested.filter((m) => m.type === id),
      }));
      return groups;
    },
    mediaUploadedGroups: (state) => {
      const translations = {
        photo: 'Auto',
        document: 'Technický preukaz',
      };

      const groupIds = getGroupIds(state.mediaUploaded);
      const groups = groupIds.map((id) => ({
        id,
        title: translations[id] || id,
        items: state.mediaUploaded.filter((m) => m.type === id),
      }));
      return groups;
    },
  },
  mutations: {
    setMediaRequested(state, payload) {
      state.mediaRequested = payload;
    },
    setProduct(state, payload) {
      switch (payload) {
        case 'Generali/HPL':
          state.config.product = 'HPL';
          break;
        case 'Generali/HPL_SKLO':
          state.config.product = 'HPL';
          break;
        case 'Generali/PZP17':
          state.config.product = 'PZP';
          break;
        case 'Generali/PZP17_SKLO':
          state.config.product = 'PZP';
          break;
        default:
          // Don't set nothing
      }
    },
  },
  actions: {
    async inspectionBegin({ state, commit }) {
      const url = `${state.config.apiBase}/v2/inspection/verify`;
      try {
        const response = await axios.post(
          url,
          {
            contractNumber: this.state.policy.contractNumber,
          },
        );
        if (response.data) {
          const { mediaRequested, product } = response.data;
          commit('setMediaRequested', mediaRequested);
          commit('setProduct', product);
          return true;
        }
        return false;
      } catch {
        return false;
      }
    },
    async submitSingleMedia({ state }, payload) {
      const url = `${state.config.apiBase}/v2/inspection/media`;
      const {
        name, image, resized, location, isSkipped, type,
      } = payload;
      const data = new FormData();

      data.append('contractNumber', this.state.policy.contractNumber);
      data.append('name', name);
      if (location?.lat && location?.lng) {
        data.append('gps', `${location.lat},${location.lng}`);
      }
      data.append('image', image);

      try {
        const response = await axios({
          method: 'post',
          url,
          data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 60000,
        });
        if (response?.data?.success) {
          const indexToReplace = state.mediaUploaded.findIndex(
            (m) => m.name === name,
          );
          if (indexToReplace === -1) {
            state.mediaUploaded.push({
              name,
              resized,
              isSkipped,
              type,
            });
          } else {
            state.mediaUploaded[indexToReplace] = {
              name,
              resized,
              isSkipped,
              type,
            };
            state.mediaUploaded.splice(); // Force re-render
          }
          return true;
        }
        // eslint-disable-next-line no-alert
        alert('Súbor nebolo možné uložiť, skúste nahrať menší súbor.');
        return false;
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert('Súbor nebolo možné uložiť, skúste nahrať menší súbor.');
        return false;
      }
    },
    async submitSingleMediaChunked({ state }, payload) {
      const {
        chunk, chunkIndex, name, location,
      } = payload;
      const url = `${state.config.apiBase}/v2/inspection/media`;
      const data = new FormData();
      data.append('contractNumber', this.state.policy.contractNumber);
      data.append('name', name);
      if (location?.lat && location?.lng) {
        data.append('gps', `${location.lat},${location.lng}`);
      }
      data.append('chunk', chunkIndex);
      data.append('image', chunk);

      const response = await axios({
        method: 'post',
        url,
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60000,
      });
      if (response?.data?.success) {
        return true;
      }
      return false;
    },
    async submitVehicleInfo({ state }) {
      const url = `${state.config.apiBase}/v2/inspection/vehicle-data`;

      const response = await axios.post(
        url,
        {
          contractNumber: state.policy.contractNumber,
          vehicle: state.vehicleInfo,
        },
      );

      if (response.data) {
        return true;
      }
      return false;
    },
    async inspectionSubmit({ state }) {
      const url = `${state.config.apiBase}/v2/inspection/finalise`;

      const response = await axios.post(
        url,
        {
          contractNumber: state.policy.contractNumber,
        },
      );

      if (response.data) {
        return true;
      }
      return false;
    },
    watchLocation({ state }) {
      const { location } = state;
      const handlePosition = (gp) => {
        location.error = null;
        location.lat = gp.coords.latitude;
        location.lng = gp.coords.longitude;
      };
      const handleError = (error) => {
        location.error = error;
      };
      navigator.geolocation.watchPosition(
        handlePosition,
        handleError,
        { timeout: 7000 },
      );
    },
    sendDataLayerTracking({ state, getters }, action) {
      if (!state.dataLayerActionsSent.includes(action)) {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: 'selfinspection',
          eventCategory: state.config.product,
          eventAction: action,
          eventLabel: getters.mediaRequestedCount,
        });
        state.dataLayerActionsSent.push(action);
      }
    },
  },
});

export default store;
