import Vue from 'vue';
import VueRouter from 'vue-router';
import Intro from '@/views/Intro.vue';
import Authorize from '@/views/Authorize.vue';
import MediaOverview from '@/views/MediaOverview.vue';
//
import Snap from '@/views/Snap.vue';
import MediaRecap from '@/views/MediaRecap.vue';
import VehicleInfo from '@/views/VehicleInfo.vue';
import ThankYou from '@/views/ThankYou.vue';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro,
  },
  {
    path: '/authorize',
    name: 'Authorize',
    component: Authorize,
  },
  {
    path: '/overview',
    name: 'MediaOverview',
    component: MediaOverview,
  },
  {
    path: '/snap/:index',
    name: 'Snap',
    props: true,
    component: Snap,
  },
  {
    path: '/recap',
    name: 'MediaRecap',
    component: MediaRecap,
  },
  {
    path: '/vehicle-info',
    name: 'VehicleInfo',
    component: VehicleInfo,
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: ThankYou,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
