import Vue from 'vue';
import scrollToElement from '../../util/scrollToElement';

export default {
  fieldsMap: {},
  getFields() {
    return Object.values(this.fieldsMap);
  },
  touchAll() {
    const fields = this.getFields();
    fields.forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      field.touched = true;
    });
  },
  check(shouldTouch = true, scrollToFirstError = true) {
    if (shouldTouch) {
      this.touchAll();
    }
    const fields = this.getFields();
    const hasErrors = fields.some((field) => !field.valid);
    if (scrollToFirstError && hasErrors) {
      Vue.nextTick(() => {
        scrollToElement({
          element: document.querySelector('.form-group.-invalid'),
          offset: 10,
        });
      });
    }

    return !hasErrors;
  },
  checkAndContinue(context) {
    if (this.check()) {
      const allSteps = context.$router.options.routes.filter(
        (route) => route.meta.step !== undefined,
      );

      const { progress } = context.$store?.state;
      if (progress?.returnTo) {
        context.$router.push(allSteps[progress.returnTo]);
        progress.returnTo = null;
      } else {
        const currentStep = context.$router.currentRoute.meta.step;
        const nextStep = currentStep + 1;

        if (progress) {
          progress.stepReached = Math.max(nextStep, progress.stepReached);
        }

        window.routeChangeEnabled = true; // Legacy

        if (currentStep + 1 <= allSteps.length) {
          context.$router.push(allSteps[currentStep + 1]);
        }
      }
    }
  },
};
