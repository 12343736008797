/*
  Usage:

  1. Install the plugin in main.js via Vue.use()
  2. Wrap each input control with a <FormGroup> component,
      passing the desired rules as a prop (e.g. :rules="['required', 'email']")
  3. Validate your form by calling $v.validator.check()

  Additional settings:

  1. Some rules require additional parameters; these can be passed as an object
      (e.g. :rules="[$v.is({ compare: 'string-to-check-against', message: 'Error message' })]").
      All rules accept the 'message' parameter.
  2. When calling the check() method, all inputs are touched by default.
      To avoid this, call check(false).
  3. Custom rules can be passed to FormGroup components in the following format:
      ruleName = (props = {}) => ({
        message: () => props.message || 'Default error message',
        pass: (value) => your function definition
      });
*/

/* eslint-disable no-param-reassign */
import validator from './handler';
import * as rules from './rules';

const ValidationPlugin = {
  install(Vue) {
    Vue.prototype.$v = {
      rules,
      validator: Vue.observable({ ...validator }),
    };
  },
};

export default ValidationPlugin;
