export default function scrollToSmooth(top) {
  const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
  if (isSmoothScrollSupported) {
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  } else { // For IE11
    window.scrollTo(0, top);
  }
}
