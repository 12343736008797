<template>
  <div class="container">
    <h1 class="page-title">
      Vaše fotografie
    </h1>
    <p class="page-subtitle">
      Ťuknutím na fotku ju nasnímate nanovo
    </p>

    <div
      v-for="group in $store.getters.mediaUploadedGroups"
      :key="group.id"
    >
      <BaseHeading
        size="small"
        class="media-list-heading"
      >
        {{ group.title }}
      </BaseHeading>
      <div class="media-list">
        <div
          class="media-preview"
          v-for="media in group.items"
          :key="media.name"
          @click="retakePhoto(media.name)"
        >
          <div class="title">
            {{ $store.getters.getMediaTitleFromName(media.name).split('/')[0] }}
          </div>
          <div class="imagewrap">
            <img
              class="image"
              :src="media.resized"
              :alt="media.name"
            >
            <img class="edit" src="@/assets/edit.svg" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="media-list">
    </div>

    <BaseButton
      block
      @click="$router.push('/vehicle-info')"
    >
      Pokračovať
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@gds/components/BaseButton';
import BaseHeading from '@gds/components/BaseHeading';

export default {
  components: {
    BaseButton,
    BaseHeading,
  },
  methods: {
    retakePhoto(name) {
      const { mediaRequested } = this.$store.state;

      const index = mediaRequested.findIndex((image) => name === image.name);
      this.$router.push({
        name: 'Snap',
        params: {
          index: (index + 1).toString(),
        },
      });
    },
  },
};
</script>
