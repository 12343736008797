const canvasResize = (image, maxSize, jpegQuality) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const aspectRatio = image.width / image.height;
  let canvasWidth = maxSize;
  let canvasHeight = maxSize;

  if (image.width > maxSize || image.height > maxSize) {
    if (aspectRatio === 1) { // Square
      canvasWidth = maxSize;
      canvasHeight = maxSize;
    } else if (aspectRatio > 1) { // Wider
      canvasWidth = maxSize;
      canvasHeight = maxSize / aspectRatio;
    } else if (aspectRatio < 1) { // Higher
      canvasWidth = maxSize * aspectRatio;
      canvasHeight = maxSize;
    }
  } else {
    canvasWidth = image.width;
    canvasHeight = image.height;
  }

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  context.drawImage(image, 0, 0, canvasWidth, canvasHeight);

  const dataURL = canvas.toDataURL('image/jpeg', jpegQuality);
  return dataURL;
};

export default canvasResize;
