<template>
  <div class="intro-blurb">
    <div class="icon">
      <FontAwesomeIcon
        :icon="icon"
        size="3x"
      />
    </div>
    <div class="content">
      <div class="text" v-if="text" v-html="text"></div>
    </div>
    <TooltipSet v-if="additionalText">
      {{ additionalText }}
    </TooltipSet>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import TooltipSet from '@gds/components/TooltipSet';

export default {
  props: {
    icon: Object,
    text: String,
    additionalText: String,
  },
  components: {
    FontAwesomeIcon,
    TooltipSet,
  },
};
</script>

<style lang="scss" scoped>
@import "@gds/styles/global.scss";

.intro-blurb {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  .icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: $primary;
  }
  .content {
    flex: 1;
  }
  .text {
    font-size: $font-size-200;
  }
  .additional-text {
    color: $gray-600;
    font-size: $font-size-100;
  }
}
</style>
