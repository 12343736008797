// Helpers
const isEmpty = (value) => value === '' || value === null || value === undefined;
const removeWhitespace = (value) => value.replace(/\s+/g, '');

// Rules
export const required = (props = {}) => ({
  message: () => props.message || 'Vyplňte pole, prosím.',
  pass: (value) => !isEmpty(value),
});

export const requiredOption = (props = {}) => ({
  message: () => props.message || 'Vyberte si, prosím.',
  pass: (value) => !isEmpty(value),
});

export const is = (props = {}) => ({
  message: () => props.message || '',
  pass: (value) => (value === props.compare),
});

export const not = (props = {}) => ({
  message: () => props.message || '',
  pass: (value) => (value !== props.compare),
});

export const min = (props = {}) => ({
  message: () => props.message || `Zadajte hodnotu aspoň ${props.compare}.`,
  pass: (value) => (value >= props.compare),
});

export const max = (props = {}) => ({
  message: () => props.message || `Zadajte hodnotu najviac ${props.compare}.`,
  pass: (value) => (value <= props.compare),
});

export const year = (props = {}) => ({
  message: () => props.message || 'Zadajte rok, prosím.',
  pass: (value) => {
    const thisYear = new Date().getFullYear();
    const minPassed = value <= thisYear - props.minAge || props.minAge == null;
    const maxPassed = value >= thisYear - props.maxAge || props.maxAge == null;

    return minPassed && maxPassed;
  },
});

export const email = (props = {}) => ({
  message: () => props.message || 'Zadajte platný e-mail.',
  pass: (value) => {
    if (!value) return true;
    // Regex from https://stackoverflow.com/a/201378
    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return emailRegex.test(value);
  },
});

export const phone = (props = {}) => ({
  message: () => props.message || 'Zadajte platný formát telefónneho čísla (9** *** ***).',
  pass: (value) => {
    if (!value) return true;
    const prefixPassed = /^[+][\d]{1,3}$/.test(value.prefix);
    const numberPassed = /^([\d]\s*){9}$/.test(value.number);
    return prefixPassed && numberPassed;
  },
});

export const postalCode = (props = {}) => ({
  message: () => props.message || 'Zadajte platné PSČ, prosím.',
  pass: (value) => {
    if (!value) return true;
    const clean = removeWhitespace(value);
    const postalCodeRegex = /^\d{5}$/;
    return postalCodeRegex.test(clean);
  },
});

export const streetNumber = (props = {}) => ({
  message: () => props.message || 'Zadajte platné číslo domu, prosím.',
  pass: (value) => {
    if (!value) return true;
    const clean = removeWhitespace(value);
    const streetNumberRegex = /^\d{1,4}\/?[a-zA-Z]?$/;
    return streetNumberRegex.test(clean);
  },
});

export const nationalIdentificationNumber = (props = {}) => ({
  message: () => props.message || 'Zadajte platné rodné číslo, prosím.',
  pass: (value) => {
    if (!value) return true;
    const clean = removeWhitespace(value);
    const ninRegex = /^\d{6}\/?\d{3,4}$/;
    if (!ninRegex.test(clean)) {
      return false;
    }

    const cleanString = clean.replace('/', '');
    const cleanNumber = Number(cleanString);
    const isDivisible = (cleanNumber % 11 === 0) || (cleanNumber % 10 === 0);
    const isLegacy = cleanString.length === 9;

    return isLegacy || isDivisible;
  },
});

export const companyRegistrationNumber = (props = {}) => ({
  message: () => props.message || 'Zadajte platné IČO, prosím.',
  pass: (value) => {
    if (!value) return true;
    const clean = removeWhitespace(value);
    const slovakCompanyRegistrationNumberRegex = /^\d{6,8}$/;
    if (!slovakCompanyRegistrationNumberRegex.test(clean)) {
      return false;
    }

    const isLegacy = clean.length !== 8;
    if (isLegacy) {
      return true;
    }

    // Check by https://phpfashion.com/jak-overit-platne-ic-a-rodne-cislo
    const [n1, n2, n3, n4, n5, n6, n7, n8] = clean.split('');
    const weight = n1 * 8 + n2 * 7 + n3 * 6 + n4 * 5 + n5 * 4 + n6 * 3 + n7 * 2;
    const remainder = weight % 11;
    const isChecksumMatching = Number(n8) === (11 - remainder) % 10;
    return isChecksumMatching;
  },
});

export const idCardNumber = (props = {}) => ({
  message: () => props.message || 'Zadajte platné číslo občianskeho preukazu, prosím.',
  pass: (value) => {
    if (!value) return true;
    const clean = removeWhitespace(value);
    const idNumberRegex = /^[a-zA-Z]{2}\d{6}$/;
    return idNumberRegex.test(clean);
  },
});

export const vinNumber = (props = {}) => ({
  message: () => props.message || 'Zadajte VIN, prosím.',
  pass: (value) => {
    if (!value) return true;
    const clean = removeWhitespace(value);
    const vinRegex = /^\w{17}$/;
    return vinRegex.test(clean);
  },
});
