import { render, staticRenderFns } from "./InputTextarea.vue?vue&type=template&id=cb3a4ab8"
import script from "./InputTextarea.vue?vue&type=script&lang=js"
export * from "./InputTextarea.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../generali-selfinspections-frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports