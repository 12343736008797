var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Overenie")]),(_vm.isError)?_c('InfoBox',{ref:"errorMessage",attrs:{"variant":"danger","size":"small"}},[_vm._v(" Uvedenú zmluvu sa nám nepodarilo nájsť. Uistite sa, že ste zadali správne údaje. ")]):_vm._e(),_c('FormGroup',{attrs:{"label":"Číslo zmluvy","rules":['required']}},[_c('InputText',{attrs:{"inputmode":"numeric"},model:{value:(_vm.$store.state.policy.contractNumber),callback:function ($$v) {_vm.$set(_vm.$store.state.policy, "contractNumber", $$v)},expression:"$store.state.policy.contractNumber"}})],1),(!_vm.$store.state.config.isProduction)?_c('FormGroup',{attrs:{"label":"Server","rules":['requiredOption']}},[_c('InputSelect',{attrs:{"options":[
        { value: 'http://localhost:5000/api', label: 'Local' },
        { value: 'https://selfinspections-backend.generali.sk/api', label: 'Prod' },
        { value: 'https://selfinspections-backend-test1.generali.sk/api', label: 'Test1' },
        { value: 'https://selfinspections-backend-test2.generali.sk/api', label: 'Test2' },
        { value: '//selfinspections-backend.test.generali.sk/api', label: 'Test webhostin11' },
      ]},model:{value:(_vm.$store.state.config.apiBase),callback:function ($$v) {_vm.$set(_vm.$store.state.config, "apiBase", $$v)},expression:"$store.state.config.apiBase"}})],1):_vm._e(),_c('BaseButton',{attrs:{"block":""},on:{"click":_vm.tryBegin}},[_vm._v(" Pokračovať ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }