<template>
  <div class="snap container">
    <div v-if="phase === 'UPLOAD'">
      <h2 class="page-title">
        {{ title.main }}
      </h2>
      <div class="page-subtitle" v-if="title.subtitle">
        {{ title.subtitle }}
      </div>
      <div class="page-subtitle" v-if="workingMedia.required === false">
        (nepovinné)
      </div>

      <div class="progress-bar">
        <div
          v-for="i in this.$store.getters.mediaRequestedCount"
          :key="i"
          :class="{
            'bar': true,
            'active': index >= i,
          }"
        />
      </div>
      <p class="page-subtitle">{{ progressText }}</p>

      <InfoBox
         v-if="media.resizedBlob && !media.isReady"
        variant="danger"
      >
        Prosím, nahrajte aktuálnu fotku.
      </InfoBox>

      <img
        class="instruction"
        :src="instructionSource"
        :alt="workingMedia.title"
      >
      <input
        class="camera-input"
        ref="cameraInput"
        type="file"
        :accept="isStaticImage ? 'image/*' : 'video/*'"
        capture="environment"
        @change="handleMediaSelect"
      />
      <div class="footer">
        <BaseButton
          block
          v-if="workingMedia.required === false"
          @click="skip"
          variant="secondary"
          :style="{ marginRight: '10px' }"
        >
          Preskočiť
        </BaseButton>
        <BaseButton
          block
          @click="openCamera"
        >
          <span v-if="isStaticImage">
            Odfotiť
          </span>
          <span v-else>
            Nahrať video
          </span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import canvasResize from '@/util/canvasResize';
import checkImage from '@/util/checkImage';
import placeholderImage from '@/util/placeholderImage';

import BaseButton from '@gds/components/BaseButton';
import InfoBox from '@gds/components/InfoBox';

export default {
  data() {
    return {
      phase: 'UPLOAD',
      media: {
        originalBlob: null,
        originalDataUri: '',

        resizedBlob: null,
        resizedDataUri: '',

        isSkipped: false,
        isReady: false,
      },
      location: {
        lat: null,
        lng: null,
      },
    };
  },
  components: {
    BaseButton,
    InfoBox,
  },
  props: {
    index: {
      type: String,
      required: true,
    },
  },
  computed: {
    isStaticImage() {
      return this.workingMedia?.type !== 'video';
    },
    progressText() {
      const { mediaRequestedCount } = this.$store.getters;
      return `Fotka ${this.index} / ${mediaRequestedCount}`;
    },
    workingMedia() {
      const index = this.index - 1;
      return this.$store.state.mediaRequested[index];
    },
    instructionSource() {
      // eslint-disable-next-line
      return require(`@/assets/instructions/${this.workingMedia.image}`);
    },
    hasLocation() {
      return !!this.location.lat && !!this.location.lng;
    },
    title() {
      const { title } = this.workingMedia;
      if (title.includes('/')) {
        const broken = title.split('/');
        return {
          main: broken[0],
          subtitle: broken[1],
        };
      }
      return {
        main: title,
        subtitle: null,
      };
    },
  },
  methods: {
    openCamera() {
      this.$refs.cameraInput.click();
    },
    handleMediaSelect(event) {
      const raw = event.target.files[0];
      this.$refs.cameraInput.value = '';

      if (raw) {
        this.media.originalBlob = raw;

        // Generate DataURI for preview
        const dataUri = URL.createObjectURL(raw);
        this.media.originalDataUri = dataUri;
        this.getLocation();

        if (this.isStaticImage) {
          // Generate small image
          const imageObject = new Image();
          imageObject.onload = async () => {
            this.media.resizedDataUri = canvasResize(imageObject, 1200, 0.7);
            this.media.resizedBlob = await (
              await fetch(this.media.resizedDataUri)
            ).blob();

            // Checkimage
            const errors = checkImage(raw);
            this.media.isReady = errors.length === 0;
            if (this.media.isReady) {
              this.saveMedia();
            }
          };
          imageObject.src = dataUri;
        } else {
          this.media.isReady = true;
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('Nedokážeme rozpoznať fotografiu');
      }
    },
    async skip() {
      this.media.resizedDataUri = placeholderImage;
      this.media.resizedBlob = await (
        await fetch(this.media.resizedDataUri)
      ).blob();
      this.media.isSkipped = true;
      this.media.isReady = true;
      this.saveMedia();
    },
    getLocation() {
      const { location } = this.$store.state;
      if (location.error) {
        return null;
      }
      return {
        lat: this.$store.state.location.lat,
        lng: this.$store.state.location.lng,
      };
    },
    async saveMedia() {
      if (!this.media.isReady) {
        return;
      }

      this.$store.state.config.isLoading = true;

      if (this.isStaticImage) {
        await this.$store.dispatch('submitSingleMedia', {
          name: this.workingMedia.name,
          image: this.media.resizedBlob,
          resized: this.media.resizedDataUri,
          location: this.getLocation(),
          isSkipped: this.media.isSkipped,
          type: this.workingMedia.type,
        });
      } else {
        const { loadingProgress } = this.$store.state.config;
        const createChunk = (file, chunkIndex, chunkSize) => {
          const chunkStart = chunkSize * chunkIndex;
          const chunkEnd = Math.min(chunkStart + chunkSize, file.size);
          const chunk = file.slice(chunkStart, chunkEnd);
          return chunk;
        };

        const file = this.media.originalBlob;
        const chunkSize = 1024 * 1024;
        const numberOfChunks = Math.ceil(file.size / chunkSize);
        loadingProgress.active = true;
        loadingProgress.total = numberOfChunks;

        for (let chunkIndex = 0; chunkIndex < numberOfChunks; chunkIndex += 1) {
          loadingProgress.current = chunkIndex;

          const chunk = createChunk(file, chunkIndex, chunkSize);
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('submitSingleMediaChunked', {
            chunk,
            chunkIndex,
            name: this.workingMedia.name,
            location: this.getLocation(),
          });
        }
        loadingProgress.active = false;

        this.$store.state.mediaUploaded.push({
          name: this.workingMedia.name,
          resized: this.media.originalDataUri,
        });
      }

      this.$store.state.config.isLoading = false;
      this.$store.dispatch('sendDataLayerTracking', `snap/${this.workingMedia.name}`);

      this.goToNext();
    },
    resetViewState() {
      this.media.originalBlob = null;
      this.media.originalDataUri = '';
      this.media.resizedBlob = null;
      this.media.resizedDataUri = '';
      this.media.isSkipped = false;
      this.media.isReady = false;
      this.location.lat = null;
      this.location.lng = null;
    },
    goToNext() {
      const { mediaRequested, mediaUploaded } = this.$store.state;
      const nextMediaIndex = mediaRequested.findIndex((image) => {
        const allUploaded = mediaUploaded.map((uploaded) => uploaded.name);
        return !allUploaded.includes(image.name);
      });

      if (nextMediaIndex === -1) {
        this.$router.push({ name: 'MediaRecap' });
      } else {
        this.$router.push({
          name: 'Snap',
          params: {
            index: (nextMediaIndex + 1).toString(),
          },
        });
        this.resetViewState();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@gds/styles/global.scss";

.instruction {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 0.5rem 2rem rgba(black, 0.15);
  margin-bottom: 2rem;
}

.camera-input {
  display: none;
}

.preview-image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.progress-bar {
  display: flex;
  justify-content: center;
  margin-top: -1rem;
  .bar {
    width: 22px;
    height: 10px;
    margin: 0 1px;
    background-color: $gray-100;
    border: 1px solid $gray-300;
    border-radius: 5px;
  }
  .bar.active {
    background-color: $primary;
    border-color: transparent;
  }
}

.footer {
  display: flex;
}

</style>
