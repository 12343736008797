<script>
import './InterfaceIcon.scss';

const iconNames = ['copy', 'external', 'info', 'lock', 'pencil', 'caret'];

const sizes = {
  xsmall: 12,
  small: 16,
  medium: 24,
  large: 40,
};

const icons = Object.fromEntries(
  // eslint-disable-next-line
  iconNames.map((name) => [name, require(`./img/${name}.svg`)]),
);

export default {
  name: 'InterfaceIcon',
  props: {
    element: {
      type: String,
      default: 'div',
      validator: (value) => ['div', 'button'].includes(value),
    },
    icon: {
      type: String,
      default: '',
      validator: (value) => iconNames.includes(value),
    },
    size: {
      type: String,
      default: 'small',
      validator: (value) => Object.keys(sizes).includes(value),
    },
    block: {
      type: Boolean,
    },
    isFlipped: {
      type: Boolean,
    },
  },
  computed: {
    sizeUsed() {
      return sizes[this.size];
    },
    iconFile() {
      return icons[this.icon];
    },
    cssClasses() {
      return {
        'interface-icon': true,
        '-block': this.block,
        '-flipped': this.isFlipped,
      };
    },
  },
  render(createElement) {
    return createElement(
      this.element,
      {
        class: this.cssClasses,
        on: {
          click: (e) => this.$emit('click', e),
        },
        attrs: {
          type: this.element === 'button' ? 'button' : null,
        },
      },
      [
        createElement('img', {
          staticStyle: {
            width: `${this.sizeUsed}px`,
            height: `${this.sizeUsed}px`,
          },
          attrs: {
            src: this.iconFile,
            alt: this.icon,
          },
        }),
      ],
    );
  },
};
</script>
