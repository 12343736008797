<template>
  <div class="app-header">
    <img src="@/assets/generali-logo.svg" class="logo" alt="">
    Samoobhliadka vozidla
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
};
</script>

<style lang="scss" scoped>
@import "@gds/styles/global.scss";

.app-header {
  background-color: $gray-200;
  border-bottom: 1px solid $gray-500;
  padding: 0 15px;
  height: 64px;
  font-weight: bold;
  color: $primary;
  display: flex;
  align-items: center;
  .logo {
    width: 50px;
    margin-right: 15px;
  }
}
</style>
