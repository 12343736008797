import { render, staticRenderFns } from "./IntroBlurb.vue?vue&type=template&id=76a7d780&scoped=true"
import script from "./IntroBlurb.vue?vue&type=script&lang=js"
export * from "./IntroBlurb.vue?vue&type=script&lang=js"
import style0 from "./IntroBlurb.vue?vue&type=style&index=0&id=76a7d780&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76a7d780",
  null
  
)

export default component.exports