<template>
  <div class="page-thank-you">
    <div class="container">
      <h1 class="page-title">
        Ďakujeme, fotky sú nahraté
      </h1>
      <img class="tick" src="@/assets/tick.svg">
      <BaseParagraph>
        Ďakujeme, fotky boli úspešne zaznamenané a prechádzajú kontrolou.
      </BaseParagraph>
      <BaseParagraph>
        Oddýchnite si teraz pri niektorom z článkov na našom blogu.
      </BaseParagraph>
      <BaseButton
        block
        @click="openWebsite"
        :style="{ marginTop: '30px' }"
      >
        Prejsť na GeneraliBalans.sk
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@gds/components/BaseButton';
import BaseParagraph from '@gds/components/BaseParagraph';

export default {
  components: {
    BaseButton,
    BaseParagraph,
  },
  methods: {
    openWebsite() {
      window.open('https://generalibalans.sk/');
    },
  },
  mounted() {
    this.$store.dispatch('sendDataLayerTracking', 'submit');
  },
};
</script>

<style lang="scss" scoped>
.tick {
  display: block;
  width: 8rem;
  height: auto;
  margin: 0 auto 1rem;
}
</style>
