<template>
  <div>
    <div class="container">
      <h1 class="page-title">Čestné prehlásenie</h1>
      <InfoBox
        variant="danger"
        size="small"
        v-if="isError"
      >
        Pri ukladaní nastala chyba
      </InfoBox>

      <FormGroup
        label="Potvrdzujem, že vozidlo…"
        :afterMessageList="[
          { for: 'UNDAMAGED', message: 'Potvrdzujem, že vozidlo je nepoškodené a je spôsobilé na prevádzku v zmysle platnej právnej úpravy s platným Technickým preukazom.' },
          { for: 'MINOR_DAMAGE', message: 'Potvrdzujem, že vozidlo má len drobné poškodenia, ktoré nemajú vplyv na jeho technický stav a teda je spôsobilé na prevádzku v zmysle platnej právnej úpravy s platným Technickým preukazom.' },
        ]"
        :afterMessageActive="$store.state.vehicleInfo.acceptance"
        :rules="['requiredOption']"
      >
        <InputOptionsRadio
          v-model="$store.state.vehicleInfo.acceptance"
          :options="[
            { value: 'UNDAMAGED', label: 'Je nepoškodené' },
            { value: 'MINOR_DAMAGE', label: 'Má len drobné poškodenia' },
          ]"
          :rules="['requiredOption']"
        />
      </FormGroup>

      <FormGroup
        label="Poznámky k prípadným poškodeniam"
      >
        <InputTextarea
          v-model="$store.state.vehicleInfo.notes"
        />
      </FormGroup>

      <BaseButton
        block
        @click="tryProceed"
      >
        Odoslať
      </BaseButton>
    </div>
  </div>
</template>

<script>
import FormGroup from '@gds/components/FormGroup';
import InputTextarea from '@gds/components/InputTextarea';
import InputOptionsRadio from '@gds/components/InputOptionsRadio';
import BaseButton from '@gds/components/BaseButton';
import InfoBox from '@gds/components/InfoBox';

export default {
  components: {
    FormGroup,
    InputTextarea,
    InputOptionsRadio,
    BaseButton,
    InfoBox,
  },
  data() {
    return {
      vehicleStatus: false,
      note: '',
      isError: false,
    };
  },
  methods: {
    async tryProceed() {
      if (this.$v.validator.check()) {
        this.$store.state.config.isFinalSubmitLoading = true;
        await this.$store.dispatch('submitVehicleInfo');
        const status = await this.$store.dispatch('inspectionSubmit');
        this.$store.state.config.isFinalSubmitLoading = false;
        if (status) {
          this.$router.push('/thank-you');
        } else {
          this.isError = true;
        }
      }
    },
  },
};
</script>

<style>

</style>
