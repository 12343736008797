<template>
  <div class="container">
    <h1 class="page-title">Overenie</h1>

    <InfoBox
      v-if="isError"
      variant="danger"
      size="small"
      ref="errorMessage"
    >
      Uvedenú zmluvu sa nám nepodarilo nájsť. Uistite sa, že ste zadali správne údaje.
    </InfoBox>

    <FormGroup
      label="Číslo zmluvy"
      :rules="['required']"
    >
      <InputText
        v-model="$store.state.policy.contractNumber"
        inputmode="numeric"
      />
    </FormGroup>

    <FormGroup
      v-if="!$store.state.config.isProduction"
      label="Server"
      :rules="['requiredOption']"
    >
      <InputSelect
        v-model="$store.state.config.apiBase"
        :options="[
          { value: 'http://localhost:5000/api', label: 'Local' },
          { value: 'https://selfinspections-backend.generali.sk/api', label: 'Prod' },
          { value: 'https://selfinspections-backend-test1.generali.sk/api', label: 'Test1' },
          { value: 'https://selfinspections-backend-test2.generali.sk/api', label: 'Test2' },
          { value: '//selfinspections-backend.test.generali.sk/api', label: 'Test webhostin11' },
        ]"
      />
    </FormGroup>

    <BaseButton
      block
      @click="tryBegin"
    >
      Pokračovať
    </BaseButton>
  </div>
</template>

<script>
import scrollToElement from '@gds/util/scrollToElement';

import BaseButton from '@gds/components/BaseButton';
import InfoBox from '@gds/components/InfoBox';
import FormGroup from '@gds/components/FormGroup';
import InputText from '@gds/components/InputText';
import InputSelect from '@gds/components/InputSelect';

export default {
  name: 'Home',
  components: {
    BaseButton,
    InfoBox,
    FormGroup,
    InputText,
    InputSelect,
  },
  data() {
    return {
      isError: false,
    };
  },
  methods: {
    async tryBegin() {
      if (this.$v.validator.check()) {
        this.$store.state.config.isLoading = true;
        const status = await this.$store.dispatch('inspectionBegin');
        this.$store.state.config.isLoading = false;
        if (status) {
          this.$router.push('/overview');
          // this.$store.dispatch('watchLocation');
          // this.$router.push('/snap/1');
        } else {
          this.isError = true;
          this.$nextTick(() => {
            scrollToElement({
              element: this.$refs.errorMessage.$el,
              offset: 0,
            });
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
