<script>
import './BaseHeading.scss';

export default {
  name: 'BaseHeading',
  props: {
    element: {
      type: String,
      default: 'h2',
    },
    size: {
      type: String,
      default: 'large',
      validator: (value) => ['large', 'small'].includes(value),
    },
  },
  computed: {
    cssClasses() {
      return [
        'base-heading',
        `-size-${this.size}`,
      ];
    },
  },
  render(createElement) {
    return createElement(
      this.element,
      {
        class: this.cssClasses,
      },
      this.$slots.default,
    );
  },
};
</script>
