import scrollToSmooth from './scrollToSmooth';

export default function scrollToElement(props) {
  const headerHeight = props.header
    ? props.header.offsetHeight
    : document.querySelector('.calculator-header-main')?.offsetHeight || 0;
  const elementOffset = props.element.getBoundingClientRect().top;
  const offset = props.offset || 0;

  const top = elementOffset + window.pageYOffset - headerHeight - offset;
  scrollToSmooth(top);
}
