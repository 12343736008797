var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Čestné prehlásenie")]),(_vm.isError)?_c('InfoBox',{attrs:{"variant":"danger","size":"small"}},[_vm._v(" Pri ukladaní nastala chyba ")]):_vm._e(),_c('FormGroup',{attrs:{"label":"Potvrdzujem, že vozidlo…","afterMessageList":[
        { for: 'UNDAMAGED', message: 'Potvrdzujem, že vozidlo je nepoškodené a je spôsobilé na prevádzku v zmysle platnej právnej úpravy s platným Technickým preukazom.' },
        { for: 'MINOR_DAMAGE', message: 'Potvrdzujem, že vozidlo má len drobné poškodenia, ktoré nemajú vplyv na jeho technický stav a teda je spôsobilé na prevádzku v zmysle platnej právnej úpravy s platným Technickým preukazom.' },
      ],"afterMessageActive":_vm.$store.state.vehicleInfo.acceptance,"rules":['requiredOption']}},[_c('InputOptionsRadio',{attrs:{"options":[
          { value: 'UNDAMAGED', label: 'Je nepoškodené' },
          { value: 'MINOR_DAMAGE', label: 'Má len drobné poškodenia' },
        ],"rules":['requiredOption']},model:{value:(_vm.$store.state.vehicleInfo.acceptance),callback:function ($$v) {_vm.$set(_vm.$store.state.vehicleInfo, "acceptance", $$v)},expression:"$store.state.vehicleInfo.acceptance"}})],1),_c('FormGroup',{attrs:{"label":"Poznámky k prípadným poškodeniam"}},[_c('InputTextarea',{model:{value:(_vm.$store.state.vehicleInfo.notes),callback:function ($$v) {_vm.$set(_vm.$store.state.vehicleInfo, "notes", $$v)},expression:"$store.state.vehicleInfo.notes"}})],1),_c('BaseButton',{attrs:{"block":""},on:{"click":_vm.tryProceed}},[_vm._v(" Odoslať ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }